<ng-container *ngIf="!column.component || isEditing">
  <!--String component-->
  <ng-container *ngIf="column.type === 'string'">
    <div
      class="td-container"
      [ngStyle]="{ 'justify-content': column.textAlign }"
    >
      <ng-container *ngIf="!isEditing">
        {{ this.label }}
      </ng-container>

      <!--Edit mode-->
      <div class="table-input" *ngIf="isEditing">
        <ht-input
          [placeholder]="column.placeholder || ''"
          [formControl]="row.formGroup.controls[column.key]"
        ></ht-input>
      </div>
    </div>
  </ng-container>

  <!--Tag component-->
  <ng-container *ngIf="column.type === 'tag'">
    <div class="tag-container" [ngStyle]="{ 'text-align': column.textAlign }">
      <div class="tag" *ngFor="let tag of row.value[column.key]">
        <div class="tag-name">{{ tag.name }}</div>
        <div class="tag-number">{{ tag.amount }}</div>
      </div>
    </div>
  </ng-container>

  <!--Dropdown component-->
  <ng-container *ngIf="column.type === 'dropdown'">
    <div
      class="td-container"
      [ngStyle]="{ 'justify-content': column.textAlign }"
    >
      <ng-container *ngIf="!isEditing">
        <ng-container *ngFor="let item of column.dropDownList">
          <ng-container *ngIf="item.id === row.value[column.key]">
            {{ item.value }}
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- Edit mode-->
      <ng-container *ngIf="isEditing">
        <ht-dropdown
          [data]="column.dropDownList"
          [formControl]="row.formGroup.controls[column.key]"
        >
        </ht-dropdown>
      </ng-container>
    </div>
  </ng-container>

  <!--Profile avatar-->
  <ng-container *ngIf="column.type === 'profileAvatar'">
    <user-avatar [user]="row.value"></user-avatar>
  </ng-container>

  <!-- Truncated long string  -->
  <ng-container *ngIf="column.type === 'truncated'">
    <div
      class="td-container"
      [ngStyle]="{ 'justify-content': column.textAlign }"
    >
      <span [tooltip]="row.value[column.key]">
        {{ row.value[column.key] | truncateLongString: 4 : 4 }}
      </span>
    </div>
  </ng-container>

  <!-- Datetime -->
  <ng-container *ngIf="column.type === 'datetime'">
    <div
      class="td-container"
      [ngStyle]="{ 'justify-content': column.textAlign }"
    >
      {{
        row.value[column.key]
          ? (row.value[column.key] | date: DEFAULT_DATE_AND_HOUR_FORMAT)
          : '--'
      }}
    </div>
  </ng-container>

  <!-- Icon -->
  <ng-container *ngIf="column.type === 'icon'">
    <i
      *ngFor="let icon of row.value[column.key]"
      [ngClass]="icon.iconClass"
      [tooltip]="icon.tooltip"
    >
    </i>
  </ng-container>
</ng-container>

<ng-template columnComponent></ng-template>
