<div class="table-wrapper" #wrapper>
  <form *ngIf="data || config.isLoading; else emptyCase" [formGroup]="form">
    <table *ngIf="config && config.columns" role="table" class="ht-table">
      <!--Header-->
      <thead *ngIf="!config.hideHeader">
        <tr>
          <ng-container
            *ngFor="let column of config.columns; index as i; first as isFirst"
          >
            <th
              [attr.colspan]="
                isFirst && (config.selectable || config.expandable) ? 2 : 1
              "
              role="rowheader"
              [ngStyle]="{
                width: column.width,
                'text-align': column.textAlign
              }"
              *ngIf="!column.isDeactivated"
            >
              {{ column.name }}
            </th>
          </ng-container>
          <th *ngIf="config.actions?.length" style="text-align: right"
            >Actions</th
          >
        </tr>
      </thead>

      <tbody
        *ngIf="!config.isLoading; else loading"
        role="rowgroup"
        formArrayName="rows"
      >
        <ng-container
          *ngFor="
            let row of rows
              | paginate
                : {
                    totalItems: config?.pagination?.serverSide
                      ? config.pagination.total
                      : rows?.length,
                    currentPage: config.pagination?.currentPage
                      ? config.pagination?.currentPage
                      : 1,
                    itemsPerPage: config?.pagination?.enabled
                      ? config.pagination.pageSize
                      : rows?.length || 10000,
                    id: tableId
                  };
            let i = index
          "
        >
          <tr
            table-row
            [row]="row"
            [tableConfig]="config"
            [editId]="editId"
            [index]="
              config.pagination?.serverSide
                ? i
                : config.pagination?.pageSize *
                    (config.pagination?.currentPage - 1) +
                  i
            "
            [isActive]="row.id === activeRow?.id"
            (rowClick)="onRowClick($event)"
            (expandToggle)="onToggleRowExpand($event)"
          >
          </tr>

          <ng-container
            *ngIf="subRowsTemplate && row.expandable && row.isExpanded"
          >
            <ng-container
              *ngTemplateOutlet="
                subRowsTemplate;
                context: {
                  $implicit: {
                    row,
                    tableConfig: config,
                    editId
                  }
                }
              "
            >
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>

<!--Paginator-->
<pagination-controls
  [id]="tableId"
  (pageChange)="onPageChange($event)"
  (pageBoundsCorrection)="onPageChange($event)"
  autoHide="true"
></pagination-controls>

<ng-template #loading>
  <tr *ngFor="let i of [1, 2, 3, 4]">
    <td colspan="100%">
      <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
    </td>
  </tr>
</ng-template>

<!--Empty case-->
<ng-template #emptyCase>
  <div role="emptyCase" class="empty-case-container">
    <ht-empty-case
      *ngIf="config.emptyCase as emptyCase"
      [buttonText]="emptyCase.buttonText"
      [title]="emptyCase.title"
      [subtitle]="emptyCase.subtitle"
      (buttonClick)="emptyCase.onButtonClick($event)"
    ></ht-empty-case>
  </div>
</ng-template>
